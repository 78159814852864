<script lang="ts" context="module">
  export interface ContentCardProps {
    title: string;
    subtitle: string;
    backgroundImageUrl: string;
    backgroundVideoUrl?: string;
    href: string;
    disableAspectRatio?: boolean;
  }
</script>

<script lang="ts">
  import clsx from "clsx";

  import { isBackgroundPlayerPaused } from "../../stores/playerStore";
  import { onMount, onDestroy } from "svelte";

  export let title: string = "";
  export let subtitle: string = "";
  export let backgroundImageUrl: string = "";
  export let backgroundVideoUrl: string = "";
  export let href: string = "";
  export let disableAspectRatio: boolean = false;
  export let variant: "videoPlayer" | "contentCard" = "contentCard";

  let video: HTMLVideoElement;

  let observer: IntersectionObserver | null = null;
  let isIntersecting = false;

  let innerWidth = 0;

  $: isPaused = video?.paused;
  let hideOverlays = false;
  let hideVideo = true;
  let zoomImage = false;

  const options = {
    root: null,
    rootMargin: "-33.33% 0% -66.67% 0%",
    threshold: 0,
  };

  function playVideo() {
    hideOverlays = true;
    hideVideo = false;
    isBackgroundPlayerPaused.set(true);
    video.play();
  }

  function pauseVideo() {
    hideOverlays = false;
    hideVideo = true;
    isBackgroundPlayerPaused.set(false);
    video.pause();
  }

  function handleMouseEnter(e: MouseEvent) {
    if (backgroundVideoUrl) {
      playVideo();
    } else {
      zoomImage = true;
    }
  }

  function handleMouseLeave(e: MouseEvent) {
    if (backgroundVideoUrl) {
      pauseVideo();
    } else {
      zoomImage = false;
    }
  }

  function handleIntersection(entries: IntersectionObserverEntry[]) {
    isIntersecting = entries.some((entry) => {
      const { isIntersecting, boundingClientRect, rootBounds } = entry;
      const isInTopOfViewport =
        boundingClientRect.y <=
        (rootBounds?.y || 0) + (rootBounds?.height || 0) / 2;
      return isIntersecting && isInTopOfViewport;
    });
    if (isIntersecting && video) {
      playVideo();
    } else if (video) {
      pauseVideo();
    }
  }

  onMount(() => {
    if (innerWidth > 425) return;
    observer = new IntersectionObserver(handleIntersection, options);
    observer.observe(video);
  });

  onDestroy(() => {
    if (observer) {
      observer.disconnect();
    }
  });

  const sharedStyles = clsx(
    disableAspectRatio ? "aspect-video md:aspect-auto" : "aspect-video",
    "@container/content-card shadow-[5px_10px_20px_0_rgba(0,0,0,0.75)] rounded-2xl  h-full flex cursor-pointer hover:no-underline relative overflow-hidden",
  );
  const contentCardStyles = "items-end md:rounded-3xl";
  const videoPlayerStyles = "items-center justify-center";
</script>

<svelte:window bind:innerWidth />

{#if variant === "contentCard"}
  <a
    {href}
    on:mouseenter={handleMouseEnter}
    on:mouseleave={handleMouseLeave}
    class={clsx(sharedStyles, contentCardStyles)}
  >
    <div
      style="background: linear-gradient(249.85deg, rgba(13, 21, 26, 0) 9.6%, rgba(13, 21, 26, 0.8) 78.39%)"
      class={`object-cover absolute w-full h-full z-20 transition-all ${hideOverlays ? "opacity-0" : "opacity-100"}`}
    ></div>
    <img
      class={`object-cover absolute w-full h-full z-10 transition-transform duration-700 transform-gpu ${zoomImage ? "scale-110" : "scale-100"}`}
      width="250"
      alt={title}
      src={backgroundImageUrl}
    />

    {#if backgroundVideoUrl}
      <video
        bind:this={video}
        bind:paused={isPaused}
        class={`brightness-50 absolute w-auto h-auto min-w-full min-h-full object-cover md:block z-20 transition-all duration-700 transform-gpu ${hideVideo ? "opacity-0" : "opacity-100"}`}
        width="250"
        playsinline
        muted
        loop
        preload="none"
        poster={backgroundImageUrl}
      >
        <source src={backgroundVideoUrl} type="video/mp4" />
      </video>
    {/if}

    <div class="p-4 lg:p-10 z-20">
      <h2 class="text-s100 font-bold text-body-large drop-shadow-lg">
        {title}
      </h2>
      <h3 class="text-white text-body-large p-0 md:pt-2 font-extralight">
        {subtitle}
      </h3>
    </div>
  </a>
{:else}
  <div
    role="presentation"
    on:mouseenter={handleMouseEnter}
    on:mouseleave={handleMouseLeave}
    class={clsx(sharedStyles, videoPlayerStyles)}
  >
    <div
      class={`object-cover absolute w-full h-full z-20 transition-all ${hideOverlays ? "opacity-0" : "opacity-100"}`}
    ></div>
    <img
      class={`object-cover absolute w-full h-full z-10 transition-transform duration-700 transform-gpu ${zoomImage ? "scale-110" : "scale-100"}`}
      width="250"
      alt={title}
      src={backgroundImageUrl}
    />
    {#if backgroundVideoUrl}
      <video
        bind:this={video}
        bind:paused={isPaused}
        class={`vimeo-iframe md:block z-20 transition-all duration-700 transform-gpu ${hideVideo ? "opacity-0" : "opacity-100"}`}
        width="250"
        playsinline
        muted
        loop
        preload="none"
        poster={backgroundImageUrl}
      >
        <source src={backgroundVideoUrl} type="video/mp4" />
      </video>
    {/if}
    <div class="z-20">
      <slot />
    </div>
  </div>
{/if}
